import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125')
];

export const server_loads = [];

export const dictionary = {
		"/(marketing)": [37,[4]],
		"/(dash)/apps/[workspace]/[environment]": [23,[2],[3]],
		"/(dash)/apps/[workspace]/[environment]/deployed/[name]": [25,[2],[3]],
		"/(dash)/apps/[workspace]/[environment]/[appId]": [24,[2],[3]],
		"/(marketing)/articles": [38,[4]],
		"/(signin)/authorize": [55],
		"/(marketing)/blog": [39,[4,5]],
		"/(marketing)/blog/[slug]": [40,[4,5]],
		"/careers": [67],
		"/(vanity)/cometeer": [60],
		"/(marketing)/company": [41,[4]],
		"/(dash)/containers/[workspace]/[environment]": [26,[2],[3]],
		"/(signin)/credit-card-gate": [56],
		"/(marketing)/customers": [42,[4]],
		"/docs": [68,[7],[8]],
		"/docs/examples": [70,[7,9],[8]],
		"/docs/examples/[...id]": [71,[7,9],[8]],
		"/docs/guide/[...id]": [72,[7,10],[8]],
		"/docs/reference/changelog": [74,[7,11],[8]],
		"/docs/reference/cli/[...id]": [75,[7,11],[8]],
		"/docs/reference/[...id]": [73,[7,11],[8]],
		"/docs/[...fallback]": [69,[7],[8]],
		"/examples": [76],
		"/(vanity)/glossary": [61],
		"/gpu-glossary": [77,[12],[13]],
		"/gpu-glossary/[...id]": [78,[12],[13]],
		"/home": [79],
		"/integrations/[slug]/configure": [80],
		"/internal": [81,[14]],
		"/internal/accounts": [82,[14,15]],
		"/internal/accounts/[workspace]": [83,[14,15]],
		"/internal/batch": [84,[14]],
		"/internal/design": [85,[14,16]],
		"/internal/design/components": [86,[14,16]],
		"/internal/design/graphics": [87,[14,16]],
		"/internal/design/typography": [88,[14,16]],
		"/internal/logs": [89,[14]],
		"/internal/lookup": [90,[14,17]],
		"/internal/lookup/[id]": [91,[14,17]],
		"/internal/queues": [92,[14]],
		"/internal/reservations": [93,[14]],
		"/internal/resources": [94,[14]],
		"/internal/resources/fullscreen": [95],
		"/internal/scheduler": [96,[14]],
		"/internal/solver": [97,[14]],
		"/internal/users": [98,[14]],
		"/internal/users/[userId]": [99,[14]],
		"/internal/workers": [100,[14]],
		"/(marketing)/legal/privacy-policy": [43,[4]],
		"/(marketing)/legal/terms": [44,[4]],
		"/(marketing)/library": [45,[4]],
		"/(signin)/login": [57],
		"/(signin)/login/sso": [58],
		"/(dash)/logs/[workspace]/[environment]": [27,[2],[3]],
		"/(dash)/logs/[workspace]/[environment]/call/[functionCallId]": [29,[2],[3]],
		"/(dash)/logs/[workspace]/[environment]/[appId]": [28,[2],[3]],
		"/notebooks": [101],
		"/notebooks/[workspace]": [102],
		"/notebooks/[workspace]/[id]": [103],
		"/playground": [104,[18]],
		"/playground/[slug]": [105,[18]],
		"/(marketing)/pricing": [46,[4]],
		"/(dash)/sandboxes/[workspace]/[environment]": [30,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]": [31,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]/create": [33,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]/[name]": [32,[2],[3]],
		"/settings": [106,[19]],
		"/settings/[[workspace]]/access-grants": [107,[19,20]],
		"/settings/[[workspace]]/audit-logs": [108,[19,20]],
		"/settings/[[workspace]]/domains": [109,[19,20]],
		"/settings/[[workspace]]/image-config": [110,[19,20]],
		"/settings/[[workspace]]/member-tokens": [111,[19,20]],
		"/settings/[[workspace]]/members": [112,[19,20]],
		"/settings/[[workspace]]/metrics": [113,[19,20]],
		"/settings/notifications": [121,[19]],
		"/settings/[[workspace]]/plans": [114,[19,20]],
		"/settings/profile": [122,[19]],
		"/settings/[[workspace]]/proxy-auth-tokens": [116,[19,20]],
		"/settings/[[workspace]]/proxy": [115,[19,20]],
		"/settings/[[workspace]]/slack-integration": [117,[19,20]],
		"/settings/[[workspace]]/tokens": [118,[19,20]],
		"/settings/[[workspace]]/usage": [119,[19,20]],
		"/settings/[[workspace]]/workspace-management": [120,[19,20]],
		"/settings/workspaces": [123,[19]],
		"/(signin)/signup": [59],
		"/(dash)/slack-link-workspace": [34,[2],[3]],
		"/slack": [124],
		"/(vanity)/startups": [62],
		"/(dash)/storage/[workspace]/[environment]": [35,[2],[3]],
		"/(dash)/storage/[workspace]/[environment]/[name]/[...dirName]": [36,[2],[3]],
		"/token-flow/[tokenFlowId]": [125,[21]],
		"/(marketing)/use-cases": [47,[4,6]],
		"/(marketing)/use-cases/audio": [48,[4,6]],
		"/(marketing)/use-cases/comp-bio": [49,[4,6]],
		"/(marketing)/use-cases/fine-tuning": [50,[4,6]],
		"/(marketing)/use-cases/image-video-3d": [51,[4,6]],
		"/(marketing)/use-cases/job-queues": [52,[4,6]],
		"/(marketing)/use-cases/language-models": [53,[4,6]],
		"/(marketing)/use-cases/sandboxes": [54,[4,6]],
		"/[workspace=workspace]/[environment]/apps/[...rest]": [63],
		"/[workspace=workspace]/[environment]/logs/[...rest]": [64],
		"/[workspace=workspace]/[environment]/secrets/[...rest]": [65],
		"/[workspace=workspace]/[environment]/storage/[...rest]": [66],
		"/(dash)/[fallback=dash]/[...rest]": [22,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';